.footer {
  background: var(--color-dark-grey);
  margin: 0;
  padding: 80px 0 32px;
  color: var(--color-white);
  font: var(--font-xs);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.footer > .wrapper {
  display: flex;
  gap: 112px;
  margin: 0 auto 54px;
  padding: 0 46px;
  max-width: 1990px;
}

/* tmp1 */
.page_light .footer > .wrapper,
.page_dark .footer > .wrapper {
  margin: 0 auto 20px;
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 60px 0 32px;
  }
  .footer > .wrapper {
    gap: 30px;
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding: 40px 0 24px;
  }
  .footer > .wrapper {
    flex-direction: column;
    gap: 15px;
    margin: 0 0 30px;
    padding: 0 15px;
    color: var(--color-m-grey);
  }
}

.footer .logo {
  margin: 0;
}

@media screen and (max-width: 600px) {
  .footer .logo {
    display: none;
  }
}

.footer__navContainer,
.footer__linksContainer,
.footer__contactsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer__contactsContainer {
  align-items: end;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 600px) {
  .footer__contactsContainer {
    align-items: flex-start;
    order: 1;
    margin: 0;
  }
  .footer__linksContainer {
    order: 2;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 750px) {
  .footer__navContainer {
    display: none;
  }
}

.footer__contactsEmail {
  display: inline-block;
  margin: 0 0 20px;
  font: var(--font-h-m);
  letter-spacing: 0.07rem;
  text-align: end;
  text-transform: capitalize;
  text-transform: lowercase;
}

.footer__contactsEmail:first-letter {
  text-transform: uppercase;
}

.footer__contactsLink {
  display: flex;
}

.footer__navItem,
.footer__contactsEmail,
.footer__contactsLink {
  transition: color 0.25s ease-out;
  color: inherit;
}

.footer__navItem:hover,
.footer__contactsEmail:hover,
.footer__contactsLink:hover {
  color: var(--color-accent);
}

@media screen and (max-width: 1000px) {
  .footer__navItem {
    font-size: 0.75rem;
  }

  .footer__contactsLink {
    font-size: 0.75rem;
  }
  .footer__contactsEmail {
    font: var(--font-l);
    font-size: clamp(0.75rem, 2vw, 1.25rem);
  }
}

@media screen and (max-width: 600px) {
  .footer__contactsEmail {
    margin: 0 0 20px;
    width: fit-content;
    color: var(--color-white);
    font-size: 1.2rem;
  }
}

.footer__contactsPic {
  margin: auto 10px auto 0;
  height: 20px;
}

@media screen and (max-width: 600px) {
  .footer__contactsLink {
    margin: 0 0 5px;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
  }

  .footer__contactsPic {
    width: 15px;
    height: 15px;
  }
}

.footer__hr {
  margin: auto;
  border: none;
  border-top: 1px solid var(--color-grey);
  padding: 0 46px;
  max-width: 1990px;
  margin: 0 30px;
}

@media screen and (max-width: 600px) {
  .footer__hr {
    box-sizing: border-box;
    margin: auto;
    width: 90%;
  }
}

.footer > .wrapper:last-of-type {
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 0;
}

.footer__terms {
  transition: color 0.25s ease-out;
  cursor: pointer;
  color: inherit;
  letter-spacing: normal;
  text-transform: capitalize;
}

.footer__terms:hover {
  color: var(--color-accent);
}

@media screen and (max-width: 600px) {
  .footer > .wrapper:last-of-type {
    justify-content: center;
    gap: 5px;
    margin-top: 32px;
    margin-bottom: 0;
  }

  .footer__terms {
    order: 1;
    margin: auto;
  }

  .footer__copyright {
    order: 2;
    margin: auto;
  }
}
