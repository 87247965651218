.header__wrapper {
  display: flex;
  justify-content: flex-end;
  z-index: 3;
  margin: 20px auto;
  padding: 0 46px;
  width: 100%;
  max-width: 1990px;
}

@media screen and (max-width: 600px) {
  .header__wrapper {
    margin: 5px auto;
    padding: 0 15px;
  }
}
