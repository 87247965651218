.header__icon {
  display: none;
  opacity: 0.85;
  cursor: pointer;
  margin: auto 0 auto 15px;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1000px) {
  .header__icon {
    display: block;
  }
}

@media screen and (min-width: 1001px) {
  .modal.hidden .header__icon {
    display: none;
  }
}
