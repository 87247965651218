.logo {
  position: relative;
  margin: auto auto auto 0;
  width: 92.333px;
  height: 60px;
}

.footer .logo {
  width: 114px;
  height: 74px;
}

@media screen and (max-width: 600px) {
  .logo {
    opacity: 0.75;
    width: 76.95px;
    height: 50px;
  }
}

.logo__img {
  height: 100%;
}
