.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 135px auto 240px;
  padding: 0 300px;
  max-width: 1440px;
  color: var(--color-white);
  text-align: center;
}

@media screen and (max-width: 900px) {
  .notFound {
    padding: 0 150px;
  }
}

@media screen and (max-width: 600px) {
  .notFound {
    padding: 0 20px;
  }
}

.notFound__img {
  margin: 0 auto;
  width: 250px;
}
.notFound__description {
  margin: 80px 0 0;
  font: var(--font-h-s);
  letter-spacing: 0.06rem;
  text-transform: uppercase;
}
.notFound__title {
  margin: 5px 0 0;
  font: var(--font-h-xl);
}
@media screen and (max-width: 900px) {
  .notFound__title {
    font: var(--font-h-l);
  }
}
@media screen and (max-width: 600px) {
  .notFound__title {
    font: var(--font-h-l);
  }
}
.notFound__text {
  margin: 20px 0 0;
  font-size: var(--font-ml);
  line-height: 1.5;
}

.notFound__link {
  transition: color 0.25s ease-out;
  border-bottom: 1px solid var(--color-accent);
  color: var(--color-accent);
}

.notFound__link:hover {
  border-bottom: 1px solid var(--color-visited);
  color: var(--color-visited);
}
