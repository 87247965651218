.pageGallery__title {
  margin: 0;
  font: var(--font-h-l);
}

@media screen and (max-width: 600px) {
  .pageGallery__title {
    font-size: 2.06rem;
  }
}

.pageGallery__grid {
  grid-gap: 20px;
  display: grid;
  grid-auto-rows: 200px;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin: 42px auto;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .pageGallery__grid {
    display: flex;
    flex-direction: column;
    margin: 32px auto 0;
    width: 100%;
  }
}

.pageGallery__img {
  cursor: pointer;
  width: 0;
  min-width: 100%;
  height: 0;
  min-height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .pageGallery__img {
    width: 100%;
    min-width: 100%;
    height: 450px;
    min-height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .pageGallery__img {
    width: 100%;
    min-width: 100%;
    height: 330px;
    min-height: 100%;
  }
}

.pageGallery__grid :nth-child(7n + 1) {
  grid-row: span 2;
  grid-column: span 3;
}

.pageGallery__grid :nth-child(7n + 2) {
  grid-row: span 2;
  grid-column: 4 / span 5;
}
.pageGallery__grid :nth-child(7n + 3) {
  grid-row: span 2;
  grid-column: span 4;
}
.pageGallery__grid :nth-child(7n + 4) {
  grid-row: span 2;
  grid-column: 5 / span 4;
}
.pageGallery__grid :nth-child(7n + 5) {
  grid-row: span 1;
  grid-column: span 2;
}
.pageGallery__grid :nth-child(7n + 6) {
  grid-row: span 2;
  grid-column: 3 / span 6;
}
.pageGallery__grid :nth-child(7n + 7) {
  grid-row: span 1;
  grid-column: span 2;
}
